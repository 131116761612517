import React, { useState, useEffect } from 'react'
import ky from 'ky' // SignUpForm uses zxcvbn which bloats its size
import FullScreenModal from '../shared_components/FullScreenModal/FullScreenModal'
import {
  DesktopUserStatus as userStatusText,
  LoginModal as modalText,
} from './accounts.json'
import { locale as urlLocale, otherModalType } from './helpers'
import withLazyLoading from '../shared_components/withLazyLoading'
import { getCSRFToken } from '../../shared/misc.js'
import { SignInForm } from './SignInForm'

import './DesktopUserStatus.scss'

const SignUpForm = withLazyLoading(() => import('./SignUpForm'))

const DesktopUserStatus = ({
  sign_in_path,
  create_account_path,
  user_session,
  is_skipping_instruction,
  contraception_methods,
  how_discovered,
  I18n_locale,
}) => {
  const [currentUser, setCurrentUser] = useState(null)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState(null)

  const locale = I18n_locale || urlLocale

  const isModalOpeningDisabled =
    window.location.pathname === sign_in_path ||
    window.location.pathname === create_account_path
  const userSession = JSON.parse(user_session)

  const csrfToken = getCSRFToken()

  // Fetch user information on initial render
  useEffect(() => {
    ky.get('/users/my_profile', { credentials: 'same-origin' })
      .json()
      .then((userJson) => setCurrentUser(userJson))
  }, [])

  const linkClickHandler = (type) => (e) => {
    e.preventDefault()
    if (!isModalOpeningDisabled) {
      setIsModalOpen(true)
      setModalType(type)
    }
  }

  return (
    <>
      <span className="DesktopUserStatus">
        {currentUser && currentUser.email ? (
          <>
            <a className="user-name" href="/users/my_account">
              {`${userStatusText[locale].hey} ${
                currentUser.display_name || currentUser.email
              }`}
            </a>
            <span className="spacer" />
            <form
              className="button_to"
              method="post"
              action="/user_sessions/current"
            >
              <input type="hidden" name="_method" value="delete" />
              <input
                className="sign-out"
                type="submit"
                value={userStatusText[locale].log_out}
              />
              <input
                type="hidden"
                name="authenticity_token"
                value={csrfToken}
              />
            </form>
          </>
        ) : (
          <>
            <a
              href={create_account_path}
              rel="nofollow"
              className="login_link signup"
              id="signup"
              onClick={linkClickHandler('create_account')}
            >
              {userStatusText[locale].create_account}
            </a>
            {' | '}
            <a
              href={sign_in_path}
              rel="nofollow"
              className="login_link signin"
              id="signin"
              onClick={linkClickHandler('sign_in')}
            >
              {userStatusText[locale].sign_in}
            </a>
          </>
        )}
      </span>

      {isModalOpen && (
        <FullScreenModal
          setIsModalOpen={setIsModalOpen}
          closeuserStatusText=""
          id="react_login_modal"
        >
          <header>
            <div className="title_header">
              {modalText[locale][`${modalType}_header`]}
            </div>

            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
              type="button"
              className="x_close"
              onClick={() => setIsModalOpen(false)}
            />

            <div className="toggle_signin">
              {modalText[locale][`${modalType}_other_option_1`]}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a
                onClick={(e) => {
                  e.preventDefault()
                  setModalType(otherModalType(modalType))
                }}
              >
                {modalText[locale][`${modalType}_other_option_2`]}
              </a>
            </div>
          </header>

          {modalType === 'sign_in' && (
            <SignInForm
              error={userSession.errors}
              email={userSession.email}
              is_skipping_instruction={is_skipping_instruction}
              csrf_token={csrfToken}
            />
          )}

          {modalType === 'create_account' && (
            <SignUpForm
              error={userSession.errors}
              contraception_methods={contraception_methods}
              how_discovered={how_discovered}
              csrf_token={csrfToken}
              is_skipping_instruction={is_skipping_instruction}
            />
          )}
        </FullScreenModal>
      )}
    </>
  )
}

export default DesktopUserStatus
