import React, { useState } from 'react'
import FullScreenModal from '../shared_components/FullScreenModal/FullScreenModal'
import { locale as urlLocale } from './helpers'
import { LocaleSwitcher as text } from './accounts.json'
import './LocaleSwitcher.scss'

const LocaleSwitcher = ({
  hasTranslation,
  switchUrl,
  noTranslationUrl,
  I18n_locale,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const locale = I18n_locale || urlLocale
  return (
    <>
      <div
        className="LocaleSwitcher"
        id="LocaleSwitcher"
        style={{ opacity: 0 }}
      >
        {hasTranslation ? (
          <a href={switchUrl}>{text[locale].button}</a>
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <a onClick={() => setIsModalOpen(!isModalOpen)}>
            {text[locale].button}
          </a>
        )}
      </div>

      {isModalOpen && (
        <LocaleSwitcherModal
          noTranslationUrl={noTranslationUrl}
          setIsModalOpen={setIsModalOpen}
          locale={locale}
        />
      )}
    </>
  )
}

const LocaleSwitcherModal = ({ noTranslationUrl, setIsModalOpen, locale }) => {
  return (
    <FullScreenModal setIsModalOpen={setIsModalOpen} id="LocaleSwitcherModal">
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        className="x_close"
        onClick={() => setIsModalOpen(false)}
        type="button"
      />
      <div className="title"> {text[locale].modal_title} </div>
      <div className="body"> {text[locale].modal_body} </div>
      <a href={noTranslationUrl} className="switch">
        {' '}
        {text[locale].switch}{' '}
      </a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a className="cancel" onClick={() => setIsModalOpen(false)}>
        {' '}
        {text[locale].cancel}{' '}
      </a>
    </FullScreenModal>
  )
}

export default LocaleSwitcher
